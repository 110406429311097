!<template>
<div :class="[{ 'active': showPopup }, 'popup-overlay']">
  <div class="popup-inr is-library ng-star-inserted">
    <div class="close-popup" @click="onClose"></div>
    <div class="library-content">
      <div class="news-pack-item">
        <div class="pack-title">
          <h3>{{item.Name}}</h3>
        </div>
        <div v-html="item.Brief"></div>
        <div class="pack-price">
          <p v-if="item.Quantity > 1 && item.PriceShowValue > item.Price" class="price-del">
            {{formatPrice(item.PriceShowValue)}}<sup>đ</sup>/{{item.Quantity}} {{item.Unit === 1 ? " Ngày" : " Tháng"}}
          </p>
          <p v-if="item.Quantity == 1 && item.PriceShowValue > item.Price" class="price-del">
            {{formatPrice(item.PriceShowValue)}}<sup>đ</sup>/{{item.Unit === 1 ? " Ngày" : " Tháng"}}
          </p>

          <p v-if="item.Quantity > 1" class="price-red">
            {{formatPrice(item.Price)}}<sup>đ</sup>/{{item.Quantity}}
            {{item.Unit === 1 ? " Ngày" : " Tháng"}}
          </p>
          <p v-if="item.Quantity == 1" class="price-red">
            {{formatPrice(item.Price)}}<sup>đ</sup>/{{item.Unit === 1 ? " Ngày" : " Tháng"}}</p>
        </div>
      </div>
      <div class="library-detail">
        <div class="library-outer">
          <div class="library-scroll">
            <h2>{{ $t("DataLib.PackDetail") }}</h2>
            <div style="margin-bottom: 30px;" v-html="item.Detail"></div>
            <!-- <h2>{{ $t("DataLib.Syntax") }}</h2> -->
            <div class="register-syntax" v-html="item.Syntax"></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
</template>

<script>
export default {
  props: ["showPopup", "item"],
  methods: {
    onClose() {
      this.$emit('onClose')
    },
    formatPrice(value) {
      let val = (value/1).toFixed(0).replace(',', '.')
      return `${val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".")}`
    }
  }
}
</script>
<style>
.popup-overlay .news-pack-item .pack-txt {
   padding: 3rem 5rem 2rem 0rem
}
</style>

<style lang="scss" scoped>
.popup-overlay {
  position: fixed;
  left: 0;
  top: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, .9);
  z-index: 50;
  text-align: center;
  display: none;
  overflow: hidden;
  overflow-y: auto;

  .register-syntax {
    color: #ed1f24;
    font-weight: bold;
  }

  .news-pack-item li {
    padding: 0.5rem 0 0.5rem 3.2rem;
    background: url('../assets/images/check-grey.png') no-repeat left 0.25rem;
    background-size: 2rem auto;
    font-size: 1.6rem;
    color: #333;
    font-weight: 400;
    margin-bottom: 1.2rem;
    line-height: 1.2;
  }

  .library-content .pack-txt {
    padding: 5rem 0 3rem;
  }

  .pack-title h3 {
    font-size: 3.6rem;
    font-weight: 900;
    font-style: italic;
    color: #fff;
    text-transform: uppercase;
  }

  .library-detail h2 {
    font-size: 2.4rem;
    font-weight: 700;
    text-transform: uppercase;
    margin-bottom: 2rem;
  }

  .library-outer {
    padding-right: 4%;
    width: 100%;
  }

  .library-detail {
    font-size: 1.6rem;
    color: #333;
    position: relative;
    width: 100%;
    height: 100%;
  }

  .library-content .news-pack-item {
    flex: 0 0 34%;
    max-width: 34%;
    margin: 0 5.5% 0 0;
    box-shadow: none;
    background-color: initial;
  }

  .popup-inr.is-library {
    max-width: 115.3rem;
    padding: 3.4rem;
    border-radius: 1rem;
    overflow: hidden;
  }

  .popup-inr {
    width: 90%;
    height: auto;
    max-width: 827px;
    max-height: inherit;
    background-color: #fff;
    text-align: left;
  }

  .popup-inr,
  .popup-overlay:after,
  .popup-overlay:before {
    position: relative;
    display: inline-block;
    vertical-align: middle;
  }

  .close-popup {
    position: absolute;
    right: 10px;
    top: 10px;
    height: 15px;
    width: 15px;
    background-size: 60% auto;
    cursor: pointer;
    z-index: 3;
    outline: none;
    background: url('../assets/images/icons/close-popup.svg') no-repeat 50%;
    opacity: 0.5;

    &:hover {
      filter: invert(20%) sepia(93%) saturate(1352%) hue-rotate(316deg) brightness(119%) contrast(119%);
    }
  }

  .library-content {
    position: relative;
    display: flex;
    justify-content: space-between;
    height: 60vh;
    z-index: 1;
  }

  .library-scroll {
    position: relative;
    width: 100%;
    height: 100%;
    overflow: hidden;
    overflow-y: auto;
    -webkit-overflow-scrolling: touch;

    h2 {
      color: black;
    }
  }

  .pack-title {
    padding: 4.5rem 5rem 4.4rem;
    background: url('../assets/images/pack-title-bg.png') no-repeat 50%;
    background-size: cover;
    border-radius: 1rem;
  }

  ul {
    list-style: none;
  }

  &.active {
    display: block;
  }

  @media screen and (min-width: 1100px) {
    &.active {
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }

  @media screen and (max-width: 1100px) {
    .pack-title h3 {
      font-size: 7.246vw;
    }

    .popup-inr.is-library {
      margin: 5vw auto;
      padding: 7vw 5vw;
    }

    .library-content {
      flex-wrap: wrap;
      height: auto;
    }

    .library-content .news-pack-item {
      flex: 0 0 100%;
      max-width: 100%;
      margin: 0 0 5vw;
    }

    .news-pack-item {
      flex: 0 0 100%;
      max-width: 100%;
      padding: 0 0 3vw;
      margin: 0 0 5.23vw;
    }

    .library-detail {
      height: auto;
      font-size: 3.75vw;
    }

    .library-outer {
      position: relative;
      left: auto;
      top: auto;
      height: auto;
    }

    .library-detail h2 {
      font-size: 6vw;
      margin-bottom: 4vw;
    }

    .library-content .pack-txt {
      padding: 6vw 0 4vw;
      text-align: center;
    }

    .news-pack-item .pack-txt {
      margin: 0;
    }

    .news-pack-item li {
      font-size: 3.8647vw;
      line-height: 1.4;
      background-size: 3.623vw auto;
      padding: 0.3vw 0 0 5vw;
      background-position: left 0.9vw;
      margin-bottom: 3.22vw;
      text-align: left;
    }
  }

  @media screen and (max-width: 700px) {
    .news-pack-item li {
      padding: 0.5rem 0 0.5rem 2.2em;
      background-size: 5rem auto!important;
    }
  }
}
</style>
