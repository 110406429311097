<template>
  <div>
    <div class="fs-section fs-library">
      <h1>{{ $t("DataLib.DATAPACKAGE LIBRARY") }}</h1>
      <div class="fs-inr library-inr">
        <div class="form-box">
          <div class="fs-group flex-spr2 row fs-group-fitler row-gap-2">
            <div class="col col-md-6 col-sm-12 mb-2__item-filter">
              <div class="fs-caption">{{ $t("DataLib.packageType") }}</div>
              <Select2
                class="select2-custom"
                :settings="{
                  minimumResultsForSearch: -1,
                  selectionCssClass: `select2-dropdown-custom`,
                }"
                :placeholder="$t('Common.Choose a data pack type')"
                v-model="currentNeed"
                :options="DataDropdownChooseANeed"
                @change="onSelectChooseANeed()"
              />
            </div>
            <div class="col-md-6 col-sm-12">
              <div class="fs-caption">{{ $t("Common.Sort") }}</div>
              <Select2
                :settings="{
                  minimumResultsForSearch: -1,
                  selectionCssClass: `select2-dropdown-custom`,
                }"
                class="select2-custom"
                :placeholder="$t('Common.Choose sorting type')"
                v-model="curentSorting"
                :options="DataSortingType"
                @change="onSelectChooseSorting()"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="fs-section fs-packs" v-if="isEmptyDataPack">
      <div class="fs-inr packs-inr">
        <div class="pack-box news-pack">
          <h2 style="display: inline-block">
            {{ $t("Common.datapack-none=message") }}
          </h2>
        </div>
      </div>
    </div>
    <div class="fs-section fs-packs" v-if="!isEmptyDataPack">
      <div class="fs-inr packs-inr">
        <div class="pack-box hot-pack">
          <h2>{{ $t("DataLib.HOT DATA PACKAGE") }}</h2>
          <div class="small-title-pack">{{ $t("DataLib.SuperOffer") }}</div>
          <div class="hot-pack-box">
            <swiper
              class="hot-pack-slider"
              :key="Banners.length"
              :slides-per-view="1"
              :centeredSlides="true"
              :autoplay="{
                delay: 3000,
                disableOnInteraction: false,
              }"
              :navigation="true"
              :space-between="1000"
              @swiper="onSwiper"
              @slideChange="onSlideChange"
              loop
            >
              <swiper-slide v-for="b in Banners" :key="b.id">
                <a class="fs-pic" :href="b.Url"
                  ><img
                    loading="lazy"
                    alt="Banner Mobile"
                    class="fs-sp"
                    :src="b.ThumbnailMobile"
                /></a>
                <a class="fs-pic desktop-img" :href="b.Url"
                  ><img
                    loading="lazy"
                    alt="Banner Desktop"
                    class="fs-pc"
                    :src="b.Thumbnail"
                /></a>
              </swiper-slide>
            </swiper>
          </div>
        </div>
        <div
          id="all"
          v-for="category in DataCategory.filter((el) => {
            if (currentNeed == 0 || currentNeed == -1) {
              return true;
            } else {
              return el.Id == currentNeed;
            }
          })"
          class="pack-box news-pack"
          :key="category.Id"
        >
          <h2 style="display: inline-block">{{ category.Name }}</h2>

          <!-- <a href="" class="fs-but type-gray" v-if="currentNeed == 0 || currentNeed == -1" @click.prevent="currentNeed = category.Id" title="Xem thêm"> Xem thêm </a> -->

          <div class="small-title-pack">
            {{ category.Note }}
          </div>

          <div class="news-pack-box">
            <div
              v-for="pack in DataPackages.filter(
                (i) => i.CategoryId == category.Id
              )"
              :key="pack.Id"
              class="news-pack-item"
            >
              <div class="pack-title">
                <h3>{{ pack.Name }}</h3>
              </div>
              <div v-html="pack.Brief"></div>
              <div class="pack-bot">
                <!-- <div class="fs-links">
                          <h3 class="text-primary h3 text-center">{{ $t('DataLib.REGISTER') }}: {{ pack.Syntax }}</h3>
                      </div> -->
                <div class="fs-links d-flex jus-sb al-c">
                  <div class="register-syntax" v-html="pack.Syntax"></div>
                  <div class="fs-link" @click="onOpenPackDetail(pack)">
                    Chi tiết
                  </div>
                </div>
                <div class="pack-price">
                  <p
                    v-if="pack.Quantity > 1 && pack.PriceShowValue > pack.Price"
                    class="price-del"
                  >
                    {{ formatPrice(pack.PriceShowValue) }}<sup>đ</sup>/{{
                      pack.Quantity
                    }}
                    {{ pack.Unit === 1 ? " Ngày" : " Tháng" }}
                  </p>
                  <p
                    v-if="
                      pack.Quantity == 1 && pack.PriceShowValue > pack.Price
                    "
                    class="price-del"
                  >
                    {{ formatPrice(pack.PriceShowValue) }}<sup>đ</sup>/{{
                      pack.Unit === 1 ? " Ngày" : " Tháng"
                    }}
                  </p>

                  <p v-if="pack.Quantity > 1" class="price-red">
                    {{ formatPrice(pack.Price) }}<sup>đ</sup>/{{
                      pack.Quantity
                    }}
                    {{ pack.Unit === 1 ? " Ngày" : " Tháng" }}
                  </p>
                  <p v-if="pack.Quantity == 1" class="price-red">
                    {{ formatPrice(pack.Price) }}<sup>đ</sup>/{{
                      pack.Unit === 1 ? " Ngày" : " Tháng"
                    }}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <PackDetail
      v-if="isShowDetail && packDetail"
      :item="packDetail"
      :showPopup="isShowDetail"
      @onClose="onClosePackDetail"
    />
  </div>
</template>

<script>
import { BModal } from "bootstrap-vue";
import { Navigation, Pagination, Autoplay } from "swiper";
import { SwiperCore, Swiper, SwiperSlide } from "swiper-vue2";
import useJwt from "@/auth/jwt/useJwt";
import EventBus from "@/global-components";
import Select2 from "v-select2-component";
import PackDetail from "./PackDetail.vue";

// Import Swiper styles
import "swiper/swiper-bundle.css";
SwiperCore.use([Navigation, Pagination, Autoplay]);
function importAll(r) {
  let images = {};
  r.keys().map((item, index) => {
    images["assets/images/ve-itel/" + item.replace("./", "")] = r(item);
  });
  return images;
}
const images = importAll(
  require.context("@/assets/images/ve-itel/", false, /.(png)$/)
);

export default {
  components: {
    Swiper,
    SwiperSlide,
    BModal,
    Select2,
    PackDetail,
  },
  data() {
    return {
      DataCategory: [],
      DataDropdownChooseANeed: [],
      DataPackages: [],
      DataSortingType: [
        { id: 1, text: "Giá từ thấp - cao" },
        { id: 2, text: "Giá từ cao - thấp" },
      ],
      isEmptyDataPack: false,
      curentSorting: -1,
      currentNeed: -1,
      modalClass: "",
      selectedDataPack: {},
      isShowDetail: false,
      packDetail: null,
      Banners: [],
    };
  },
  watch: {
    $route(to, from) {
      this.fetch();
    },
  },
  setup() {
    const onSwiper = (swiper) => {
      console.log(swiper);
    };
    const onSlideChange = () => {
      console.log("slide change");
    };
    return {
      onSwiper,
      onSlideChange,
    };
  },
  mounted() {
    // insider_object
    window.insider_object = window.insider_object || {};
    window.insider_object.page = {
      type: "thu-vien-goi-cuoc",
    };
    this.setMetaData();
    this.setAnimation();
    this.fetch();
    var t_ = this;
    EventBus.$on("CHANGE_LANG", function (payLoad) {
      t_.fetch();
    });
  },
  methods: {
    Img(pic) {
      return images[pic];
    },
    setMetaData() {
      const metas = document.getElementsByTagName("meta");
      for (let i = 0; i < metas.length; i++) {
        if (metas[i].getAttribute("name") == "description") {
          metas[i].setAttribute(
            "content",
            "Tổng hợp các gói cước mới nhất do iTel cung cấp, tham gia đăng ký ngay để nhận nhiều các ưu đãi từ iTel"
          );
        }
      }
      const titles = document.getElementsByTagName("title");
      titles[0].innerHTML = "Thư viện gói cước iTel";
    },
    fetch() {
      this.loading(true);
      useJwt
        .post("getMasterData/AllDataPack", {
          columnFilters: {},
          sort: [],
          page: 1,
          pageSize: 1000,
          lang: this.$l,
        })
        .then((response) => {
          console.log("response", response.data.result);
          this.DataCategory = response.data.result.DataCategory;
          this.DataDropdownChooseANeed =
            response.data.result.DataCategory?.map((el) => {
              return { id: el.Id, text: el.Name };
            }) || [];
          this.DataDropdownChooseANeed.splice(0, 0, {
            id: "0",
            text: "Tất cả",
          });
          this.DataPackages = response.data.result.DataPackages;
          this.Banners = response.data.result.HotBanners;
          this.loading(false);
        })
        .catch((err) => {
          this.loading(false);
          this.$toast.error(
            "Có lỗi trong khi lấy dữ liệu, Quý khách vui lòng thử lại!",
            { icon: true, closeButton: "button" }
          );
        });
    },
    openVideo(url) {
      this.modalClass = "modal__open";
      this.videoId =
        "https://www.youtube.com/embed/" +
        url +
        "?rel=0&amp;autoplay=1&amp;playsinline=1";
    },
    closeVideo() {
      this.videoId = "";
      this.modalClass = "";
    },
    setAnimation() {
      const windowH = window.screen.height;
      const headH = document.querySelector(".fs-header").clientHeight;
      [].slice
        .call(
          document.querySelectorAll(
            ".fs-banner, .choose-pic, .choose-txt, .pack-txt, .pack-info, .i-news-infos, .video-box, .support-pic, .support-txt, .like-pic, .welcome-inr .fs-txt, .welcome-inr .fs-pic, .why-inr .fs-pic, .item-reseaon, .hand-pic, .hand-txt,.about-item, .block-title, .mission-item, .value-item, .promotion-video-box, .vision-inr, .fs-distributions .fs-inr"
          )
        )
        .forEach((elm) => {
          const style = window.getComputedStyle(elm, null);
          const elmH = style.getPropertyValue("height");
          const inputT = windowH - elm.getBoundingClientRect().top;
          if (inputT > 0 && inputT < windowH + parseInt(elmH, 10) - headH / 2) {
            elm.classList.add("fs-ani");
          } else {
            elm.classList.remove("fs-ani");
          }
        });
    },
    seeDetail: function (packId, categoryId) {
      this.selectedDataPack = this.DataPackages.find((x) => x.Id === packId);
      this.$bvModal.show("detail-modal");
    },
    register: function (packId, categoryId) {
      this.isShowDetail = false;
      if (this.isSearchMode) {
        this.selectedDataPack = this.dataPackageAsync.filter(
          (x) => x.Id === packId
        )[0];
      } else {
        this.selectedDataPack = this.pageData.ListDataCatagory.filter(
          (x) => x.DataCategory.Id === categoryId
        )[0].DataPackages.filter((x) => x.Id === packId)[0];
      }
      CommonService.addClass("body", "fs-no-scroll");
      this.isShowPopup = true;
    },
    onSelectChooseANeed() {
      console.log("onSelectChooseANeed", this.currentNeed);
      if (this.currentNeed != 0 && this.currentNeed != -1) {
        if (
          this.DataPackages.filter((el) => el.CategoryId == this.currentNeed)
            .length == 0
        ) {
          this.isEmptyDataPack = true;
        } else {
          this.isEmptyDataPack = false;
        }
      } else {
        this.isEmptyDataPack = false;
      }
    },
    onSelectChooseSorting() {
      if (this.curentSorting == 1) {
        this.DataPackages = this.DataPackages.sort(
          (a, b) => a?.Price - b?.Price
        );
      } else if (this.curentSorting == 2) {
        this.DataPackages = this.DataPackages.sort(
          (a, b) => b?.Price - a?.Price
        );
      }
    },
    onOpenPackDetail(pack) {
      this.packDetail = pack;
      const app = document.getElementsByTagName("body");
      if (app && app[0]) {
        app[0].classList.add("hidden-scroll");
      }
      this.isShowDetail = true;
    },
    onClosePackDetail() {
      const app = document.getElementsByTagName("body");
      if (app && app[0]) {
        app[0].classList.remove("hidden-scroll");
      }
      this.packDetail = null;
      this.isShowDetail = false;
    },
    formatPrice(value) {
      let val = (value / 1).toFixed(0).replace(",", ".");
      return `${val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".")}`;
    },
  },
};
</script>
<style lang="scss">
.row-gap-2 {
  row-gap: 20px;
}
.select2-results__option {
  font-size: 1.8rem;
  font-weight: 400 !important;
}
@media screen and (max-width: 1100px) {
  .select2-results__option {
    font-size: 3.8647vw;
    font-weight: 400 !important;
  }
}
</style>
<style>
.hidden-scroll {
  overflow: hidden;
}

.d-flex {
  display: flex;
}

.jus-sb {
  justify-content: space-between;
}

.al-c {
  align-items: center;
}

.fs-links > .register-syntax {
  color: #ed1f24;
  font-weight: bold;
  font-size: 16px;
  padding-left: 3rem;
}

.news-pack-item li {
  padding: 0.5rem 0 0.5rem 3.2rem;
  background-size: 2rem !important;
  font-size: 1.6rem;
  color: #333333;
  font-weight: 400;
  margin-bottom: 1.2rem;
  line-height: 1.2;
}

@media screen and (max-width: 1100px) {
  .fs-links > .register-syntax {
    color: #ed1f24;
    font-weight: bold;
    font-size: 3.623vw;
    padding-left: 6.44vw;
  }

  .fs-group-fitler {
    display: flex;
    flex-direction: column;
  }

  .mb-2__item-filter {
    margin-bottom: 10px;
  }

  .news-pack-item li {
    padding: 0.5rem 0 0.5rem 4rem !important;
    background-size: 3rem !important;
  }
}

@media screen and (max-width: 700px) {
  .news-pack-item li {
    padding: 0.5rem 0 0.5rem 2.5rem !important;
    background-size: 1.8rem !important;
  }
}
</style>
