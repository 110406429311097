var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"fs-section fs-library"},[_c('h1',[_vm._v(_vm._s(_vm.$t("DataLib.DATAPACKAGE LIBRARY")))]),_c('div',{staticClass:"fs-inr library-inr"},[_c('div',{staticClass:"form-box"},[_c('div',{staticClass:"fs-group flex-spr2 row fs-group-fitler row-gap-2"},[_c('div',{staticClass:"col col-md-6 col-sm-12 mb-2__item-filter"},[_c('div',{staticClass:"fs-caption"},[_vm._v(_vm._s(_vm.$t("DataLib.packageType")))]),_c('Select2',{staticClass:"select2-custom",attrs:{"settings":{
                minimumResultsForSearch: -1,
                selectionCssClass: "select2-dropdown-custom",
              },"placeholder":_vm.$t('Common.Choose a data pack type'),"options":_vm.DataDropdownChooseANeed},on:{"change":function($event){return _vm.onSelectChooseANeed()}},model:{value:(_vm.currentNeed),callback:function ($$v) {_vm.currentNeed=$$v},expression:"currentNeed"}})],1),_c('div',{staticClass:"col-md-6 col-sm-12"},[_c('div',{staticClass:"fs-caption"},[_vm._v(_vm._s(_vm.$t("Common.Sort")))]),_c('Select2',{staticClass:"select2-custom",attrs:{"settings":{
                minimumResultsForSearch: -1,
                selectionCssClass: "select2-dropdown-custom",
              },"placeholder":_vm.$t('Common.Choose sorting type'),"options":_vm.DataSortingType},on:{"change":function($event){return _vm.onSelectChooseSorting()}},model:{value:(_vm.curentSorting),callback:function ($$v) {_vm.curentSorting=$$v},expression:"curentSorting"}})],1)])])])]),(_vm.isEmptyDataPack)?_c('div',{staticClass:"fs-section fs-packs"},[_c('div',{staticClass:"fs-inr packs-inr"},[_c('div',{staticClass:"pack-box news-pack"},[_c('h2',{staticStyle:{"display":"inline-block"}},[_vm._v(" "+_vm._s(_vm.$t("Common.datapack-none=message"))+" ")])])])]):_vm._e(),(!_vm.isEmptyDataPack)?_c('div',{staticClass:"fs-section fs-packs"},[_c('div',{staticClass:"fs-inr packs-inr"},[_c('div',{staticClass:"pack-box hot-pack"},[_c('h2',[_vm._v(_vm._s(_vm.$t("DataLib.HOT DATA PACKAGE")))]),_c('div',{staticClass:"small-title-pack"},[_vm._v(_vm._s(_vm.$t("DataLib.SuperOffer")))]),_c('div',{staticClass:"hot-pack-box"},[_c('swiper',{key:_vm.Banners.length,staticClass:"hot-pack-slider",attrs:{"slides-per-view":1,"centeredSlides":true,"autoplay":{
              delay: 3000,
              disableOnInteraction: false,
            },"navigation":true,"space-between":1000,"loop":""},on:{"swiper":_vm.onSwiper,"slideChange":_vm.onSlideChange}},_vm._l((_vm.Banners),function(b){return _c('swiper-slide',{key:b.id},[_c('a',{staticClass:"fs-pic",attrs:{"href":b.Url}},[_c('img',{staticClass:"fs-sp",attrs:{"loading":"lazy","alt":"Banner Mobile","src":b.ThumbnailMobile}})]),_c('a',{staticClass:"fs-pic desktop-img",attrs:{"href":b.Url}},[_c('img',{staticClass:"fs-pc",attrs:{"loading":"lazy","alt":"Banner Desktop","src":b.Thumbnail}})])])}),1)],1)]),_vm._l((_vm.DataCategory.filter(function (el) {
          if (_vm.currentNeed == 0 || _vm.currentNeed == -1) {
            return true;
          } else {
            return el.Id == _vm.currentNeed;
          }
        })),function(category){return _c('div',{key:category.Id,staticClass:"pack-box news-pack",attrs:{"id":"all"}},[_c('h2',{staticStyle:{"display":"inline-block"}},[_vm._v(_vm._s(category.Name))]),_c('div',{staticClass:"small-title-pack"},[_vm._v(" "+_vm._s(category.Note)+" ")]),_c('div',{staticClass:"news-pack-box"},_vm._l((_vm.DataPackages.filter(
              function (i) { return i.CategoryId == category.Id; }
            )),function(pack){return _c('div',{key:pack.Id,staticClass:"news-pack-item"},[_c('div',{staticClass:"pack-title"},[_c('h3',[_vm._v(_vm._s(pack.Name))])]),_c('div',{domProps:{"innerHTML":_vm._s(pack.Brief)}}),_c('div',{staticClass:"pack-bot"},[_c('div',{staticClass:"fs-links d-flex jus-sb al-c"},[_c('div',{staticClass:"register-syntax",domProps:{"innerHTML":_vm._s(pack.Syntax)}}),_c('div',{staticClass:"fs-link",on:{"click":function($event){return _vm.onOpenPackDetail(pack)}}},[_vm._v(" Chi tiết ")])]),_c('div',{staticClass:"pack-price"},[(pack.Quantity > 1 && pack.PriceShowValue > pack.Price)?_c('p',{staticClass:"price-del"},[_vm._v(" "+_vm._s(_vm.formatPrice(pack.PriceShowValue))),_c('sup',[_vm._v("đ")]),_vm._v("/"+_vm._s(pack.Quantity)+" "+_vm._s(pack.Unit === 1 ? " Ngày" : " Tháng")+" ")]):_vm._e(),(
                    pack.Quantity == 1 && pack.PriceShowValue > pack.Price
                  )?_c('p',{staticClass:"price-del"},[_vm._v(" "+_vm._s(_vm.formatPrice(pack.PriceShowValue))),_c('sup',[_vm._v("đ")]),_vm._v("/"+_vm._s(pack.Unit === 1 ? " Ngày" : " Tháng")+" ")]):_vm._e(),(pack.Quantity > 1)?_c('p',{staticClass:"price-red"},[_vm._v(" "+_vm._s(_vm.formatPrice(pack.Price))),_c('sup',[_vm._v("đ")]),_vm._v("/"+_vm._s(pack.Quantity)+" "+_vm._s(pack.Unit === 1 ? " Ngày" : " Tháng")+" ")]):_vm._e(),(pack.Quantity == 1)?_c('p',{staticClass:"price-red"},[_vm._v(" "+_vm._s(_vm.formatPrice(pack.Price))),_c('sup',[_vm._v("đ")]),_vm._v("/"+_vm._s(pack.Unit === 1 ? " Ngày" : " Tháng")+" ")]):_vm._e()])])])}),0)])})],2)]):_vm._e(),(_vm.isShowDetail && _vm.packDetail)?_c('PackDetail',{attrs:{"item":_vm.packDetail,"showPopup":_vm.isShowDetail},on:{"onClose":_vm.onClosePackDetail}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }